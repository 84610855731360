.iconic-pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &__link {
        font-weight: bold;
        text-decoration: none;
        position: relative;

        &:hover {
            &:link,
            &:visited {
                color:$color-primary;
                }
            }

        &--is-disabled {
            font-weight: normal;
            color: gray;
            }

        &--prev {
            padding-left: 20px;

            &::before {
                content: '\2039';
                display: block;
                position: absolute;
                font-size: 20px;
                left:0;
                top:-1px;
                font-weight: bold;
                }
            }

        &--next {
            padding-right: 20px;

            &::after {
                content: '\203A';
                display: block;
                position: absolute;
                font-size: 20px;
                right:0;
                top:-1px;
                font-weight: bold;
                }
            }
        }
    }


