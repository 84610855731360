.fullwidth {
    position: relative;
    margin-bottom:$grid-gutter-half;

    &__image {
        height:30vh;
        min-height:200px;
        overflow:hidden;
        display: block;
        width:100%;
        }

    &__image > * {
        height:100%;
        min-width:100%;
        object-fit:cover;
        }

    &__overlay {
        position: relative;
        width:calc(100% - #{$grid-gutter});
        margin:-20px auto 0;
        color:white;
        background: url("/assets/images/background_overlay.jpg") center center no-repeat;
        background-size: cover;
        padding:$grid-gutter-half;
        min-height:280px;
        }

    &__overlay .text-link:hover {
        color:white;
        }

    &__logo {
        position: absolute;
        bottom:5px;
        left:$grid-gutter;
        display: none;
        }
    }


@media screen and (min-width:$bp_md) {

    .fullwidth {
        margin-bottom:30px;

        &__image {
            height:40vh;
            min-height:400px;
            }

        &__overlay {
            position: absolute;
            bottom:-30px;
            max-width:540px;
            margin:0;
            padding:$grid-gutter;
            min-height:350px;
            }

        &__overlay--right {
            right:$grid-gutter-half;
            }

        &__overlay--left {
            left:$grid-gutter-half;
            }
        }

    }

@media screen and (min-width:$container-max-width) {

    .fullwidth {

        &__overlay {
            margin:0 15px;
            }

        &__logo {
            display: initial;
            }
        }

    .overlay__container {
        background:transparent;
        position: absolute;
        width:100%;
        height:100%;
        top:0;
        left:50%;
        transform:translateX(-50%);
        max-width: $container-max-width;
        }

    }
