.main {
    padding:0;
    }

.cta {
    text-align: center;
    margin-bottom:$grid-gutter-half;
    }

.cta__list {
    margin:0;
    padding:0;
    
    &--item {
        display:inline;
        padding-left:10px;
        margin-left:10px;
        position: relative;
        }
    
    &--item:before {
        content:'\2022';
        position: absolute;
        left:-7px;
        top:0px;
        }
    
    &--item:first-child {
        padding-left:0;
        margin-left:0;
        background:transparent;
        }
    
    &--item:first-child:before {
        content:'';
        }
    }


.row-content {
    
    &__image {
        margin-bottom:$grid-gutter-half;
        }

    &__video {
        margin-bottom:$grid-gutter-half;
        width: 100%;
        min-height: 180px;
        background-color: black;
        }
    
    &:after {
        content:'';
        display: block;
        clear:both;
        }
    
    }

.text-link {
    font-weight: bold;
    text-decoration: none;
    position: relative;
    padding-right:20px;
    
    &:after {
        content: '\203A';
        display: block;
        position: absolute;
        font-size:20px;
        right:0;
        top:-1px;
        }
    
    &:hover {
        color:$color-primary;
        }
    }



.maps-frame {
    height:40vh;
    width:100vw;
    position: relative;
    }

@media screen and (min-width:$bp_md) {
    .row-content {
        $img-max-width: 400px;
        $video-max-width: 500px;

        &__image {
            max-width:$img-max-width;
            }

        &__video {
            max-width:$video-max-width;
            min-height: 280px;
            margin-left: 20px;
        }

        &--right {
            float:right;
        }
    }
}

@media screen and (min-width:$container-max-width) {
    
    .main {
        padding:$grid-gutter-half 0 0;
        }
        
    }