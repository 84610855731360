.team {
    margin-bottom:$grid-gutter-half;
    
    .layout--inset:after {
        content: '';
        clear: both;
        display: block;
        }
    }

.team__member {
    margin-top:$grid-gutter-half;
    margin-bottom:$grid-gutter-half;
    
    &--image {
        display: block;
        width:100%;
        padding-top:85%;
        position: relative;
        overflow: hidden;
        margin-bottom:$grid-gutter-half;
        }
    
    &--name {
        font-size:26px;
        line-height:30px;
        }
    
    &--function {
        font-size: 20px;
        line-height:26px;
        font-style: italic;
        }
    
    &--image > * {
        position: absolute;
        width:100%;
        top:50%;
        transform:translateY(-50%);
        }
        
    }




@media screen and (min-width:$bp_sm) {
    
    .team__member {
        width:calc(50% - 15px);
        float:left;
        margin-left:30px;
        
        &:nth-child(even) {
            margin-left:0;
            }
        }
    
    }

@media screen and (min-width:$bp_md) {
    
    .team__member {
        width:calc(33% - 20px);
        
        &:nth-child(even) {
            margin-left:30px;
            }
        
        &:nth-child(3n+2) {
            margin-left:0;
            }
          
        }
    
    }

@media screen and (min-width:$bp_lg) {
    
    .team__member {
        width: calc(33% - 23px);
        
        &:nth-child(even) {
            margin-left: 30px;
            }
        
        &:nth-child(3n+2) {
            margin-left: 30px;
            }
        
        &:nth-of-type(4n+1) {
            margin-left: 0;
            }
        }
    }