// hamburger vars
$hamburger-padding-x                       : 15px !default;
$hamburger-padding-y                       : 15px !default;
$hamburger-layer-width                     : 30px !default;
$hamburger-layer-height                    : 3px !default;
$hamburger-layer-spacing                   : 5px !default;
$hamburger-layer-color                     : white !default;
$hamburger-layer-border-radius             : 3px !default;
$hamburger-hover-opacity                   : 0.7 !default;
$hamburger-hover-transition-duration       : 0.15s !default;
$hamburger-hover-transition-timing-function: linear !default;

$hamburger-hover-use-filter: false !default;
$hamburger-hover-filter    : opacity(50%) !default;

$hamburger-types: (
    slider
) !default;