.footer {
    background-color:$color-primary;
    padding:$grid-gutter-half;
    font-size:16px;
    color:white;
    
    &__column {
        margin-bottom:calc(2 * #{$grid-gutter});
        }
    
    &__column:last-child {
        margin-bottom:0;
        }
    
    &__column :link {
        color:white;
        }
    
    &__column :link:hover {
        text-decoration: underline;
        }
        
    .btn-social {
        width:25px;
        height:25px;
        margin-top:10px;
        }
    
    .btn-social--facebook {
        background:url("/assets/images/icon-facebook-inverse.svg") center center no-repeat;
        background-size:auto 100%;
        }

    .btn-social--instagram {
        background:url("/assets/images/icon-instagram-inverse.svg") center center no-repeat;
        background-size:auto 100%;
        }

    .btn-social--pinterest {
        background:url("/assets/images/icon-pinterest-inverse.svg") center center no-repeat;
        background-size:auto 100%;
        }
    
    .copyright {
        color:white;
        }
    }

@media screen and (min-width:$bp_md) {
    
    .footer {
        
        .container {
            display: flex;
            }
        
        &__column {
            flex:1;
            width:0;
            margin-bottom:0;
            }
        
        .copyright {
            display: flex;
            justify-content: flex-end;
            align-items:flex-end;
            }
        
        .contact__row {
            margin-bottom:0;
            }
        }
    
    
    
    }