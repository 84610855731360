
.socials {
    display: block;
    margin:10px 0 0;
    padding:0;
    }

.socials > * {
    display: inline-block;
    }

.btn-social {
    display: inline-block;
    width:20px;
    height:20px;
    margin-right:5px;
    text-decoration: none;
    text-indent: -9999px;
    
    &--facebook {
        background:url("/assets/images/icon-facebook.svg") center center no-repeat;
        background-size:auto 100%;
        }
    
    &--instagram {
        background:url("/assets/images/icon-instagram.svg") center center no-repeat;
        background-size:auto 100%;
        }
    
    &--pinterest {
        background:url("/assets/images/icon-pinterest.svg") center center no-repeat;
        background-size:auto 100%;
        }
    }
