.wrapper {
    min-height: 100vh;
    box-shadow: 0 5px 3px 1px rgba(black, .15);
    transition: none;

    &.is-animating {
        transition: transform .5s 0s ease-out;
        }
    }

.wrapper__inner {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    }

.container {
    width:100%;
    max-width:$container-max-width;
    margin:0 auto;
    }

.heading {

    &--underlined {
        position: relative;
        padding-bottom:$grid-gutter-half;
        margin-bottom:$grid-gutter-half;
        }

    &--underlined:before {
        position: absolute;
        content:'';
        background:$color-primary;
        width:65px;
        height:6px;
        bottom:0;
        left:0;
        }
    }

.heading--inverse {
    color:white;

    &.heading--underlined:before {
        background:white;
        }
    }


.layout {

    &--boxed {
        padding:$grid-gutter-half;
        max-width:$container-max-width;
        margin:0 auto;
        }

    &--inset {
        padding:$grid-gutter-half;
        background:white;
        }
    }

.link--underline {
    position: relative;
    clear:both;

    &:before {
        content:'';
        width:1px;
        display: block;
        background:white;
        height:2px;
        position: absolute;
        bottom:-3px;
        left:0;
        transition:width .3s ease, opacity .3s ease;
        opacity: 0;
        }

    &:hover:before {
        width: 50px;
        transition:width .3s ease, opacity .3s ease;
        opacity: 1;
        }
    }

.layout--inset .link--underline:hover:before {
    background: $color-primary;
    }

@media screen and (min-width:$bp-sm) {

    .layout {

        &--inset {
            padding:$grid-gutter;
            background:white;
            }
        }

    }

@media screen and (min-width:$container-max-width) {

    .layout {

        &--boxed {
            padding:$grid-gutter;
            max-width:$container-max-width;
            margin:0 auto;
            }
        
        &--boxed.cta {
            padding-top:0;
            }
        }

    }
