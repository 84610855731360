$font-copy-fallback: sans-serif;
$font-heading-fallback: sans-serif;
$font-copy: 'Century Gothic', $font-copy-fallback;
$font-heading: 'Century Gothic', $font-copy-fallback;


@font-face {
      font-family: 'Century Gothic';
      font-style: normal;
      src: local('Century Gothic'),
      url('/assets/fonts/CenturyGothic.woff2') format('woff2'),
      url('/assets/fonts/CenturyGothic.woff') format('woff');
      font-weight: normal;
}

@font-face {
      font-family: 'Coco Gothic';
      src: local('Coco Gothic'),
      url('/assets/fonts/CocoGothic-UltraLight.woff2') format('woff2'),
      url('/assets/fonts/CocoGothic-UltraLight.woff') format('woff');
      font-weight: 200;
      font-style: normal;
}