.hero-img {
    height:40vh;
    min-height:200px;
    max-height:630px;
    background:url("/assets/images/header_image.jpg") top center no-repeat;
    background-size:cover;
    position: relative;
    z-index: 0;
    overflow: hidden;

    &__overlay {
        background:rgba($color-primary, 0.65);
        position: absolute;
        height:100%;
        width:50%;
        right:0;
        top:0;
        z-index: 1;
        }

    &__slogan {
        text-transform: uppercase;
        }

    &__container {
        position: relative;
        height:100%;
        }

    }

.hero-img__slogan {
    color:white;
    position: absolute;
    bottom:$grid-gutter-half;
    width:10px;
    left:50vw;
    font-size:26px;
    letter-spacing: 2px;
    transition: font-size .3s ease, bottom .3s ease;
    z-index: 2;

    &--left,
    &--right {
        position: absolute;
        bottom:0;
        width:calc(50vw - 8px);
        display: block;
        }

    &--left {
        left:-50vw;
        text-align: right;
        }

    &--right {
        left:8px;
        }
    }

@media screen and (min-width:$bp-md) {

    .hero-img__slogan {
        font-size:60px;
        bottom:40px;
        }

    }

@media screen and (min-width:$container-max-width) {

    .hero-img {
        &__container {
            padding: $grid-gutter;
            }
        }
    }
