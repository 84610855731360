.header {
    position: relative;
    }

.logo,
.nav {
    position: absolute;
    z-index: 5;
    top:$grid-gutter-half;
    }

.logo {
    width:100px;
    left:$grid-gutter-half;
    transition: width .3s ease;
    }

.hamburger {
    position: absolute;
    right:$grid-gutter-half;
    z-index: 6;
    
    opacity: 1;
    transition: opacity .3s ease;
    visibility: visible;
    
    //display: none;
    display:inline-block;
    }

@media screen and (min-width:$bp-md) {
    
    .logo {
        width:150px;
        transition: width .3s ease;
        }
    
    }

@media screen and (min-width:$bp-lg) {
    
    .hamburger {
        
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease;
        }
    }