@font-face {
  font-family: 'Century Gothic';
  font-style: normal;
  src: local("Century Gothic"), url("/assets/fonts/CenturyGothic.woff2") format("woff2"), url("/assets/fonts/CenturyGothic.woff") format("woff");
  font-weight: normal;
}

@font-face {
  font-family: 'Coco Gothic';
  src: local("Coco Gothic"), url("/assets/fonts/CocoGothic-UltraLight.woff2") format("woff2"), url("/assets/fonts/CocoGothic-UltraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
}

body {
  color: black;
  font-size: 16px;
  background-color: #e6e6e6;
  line-height: 24px;
  overflow-x: hidden;
}

img {
  max-width: 100%;
  vertical-align: middle;
}

iframe,
object,
embed {
  max-width: 100%;
  vertical-align: middle;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 1em 0;
}

:link {
  text-decoration: none;
  cursor: pointer;
}

:link:hover {
  color: #b01116;
}

:link,
:visited {
  color: inherit;
}

p {
  line-height: 1.4em;
  margin-bottom: 1.25em;
}

.cf::after {
  content: "";
  display: table;
  clear: both;
}

.is-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
}

.absolute {
  position: absolute;
}

body {
  font-family: "Century Gothic", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Century Gothic", sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
  text-transform: uppercase;
}

h1 {
  font-size: 28px;
  line-height: 28px;
}

h2 {
  font-size: 26px;
  line-height: 30px;
}

@media screen and (min-width: 768px) {
  body {
    font-size: 18px;
    line-height: 27px;
  }
  h1 {
    font-size: 30px;
    line-height: 30px;
  }
  h2 {
    font-size: 21px;
    line-height: 25px;
  }
}

.wrapper {
  min-height: 100vh;
  box-shadow: 0 5px 3px 1px rgba(0, 0, 0, 0.15);
  transition: none;
}

.wrapper.is-animating {
  transition: transform .5s 0s ease-out;
}

.wrapper__inner {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.container {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.heading--underlined {
  position: relative;
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.heading--underlined:before {
  position: absolute;
  content: '';
  background: #b01116;
  width: 65px;
  height: 6px;
  bottom: 0;
  left: 0;
}

.heading--inverse {
  color: white;
}

.heading--inverse.heading--underlined:before {
  background: white;
}

.layout--boxed {
  padding: 15px;
  max-width: 1140px;
  margin: 0 auto;
}

.layout--inset {
  padding: 15px;
  background: white;
}

.link--underline {
  position: relative;
  clear: both;
}

.link--underline:before {
  content: '';
  width: 1px;
  display: block;
  background: white;
  height: 2px;
  position: absolute;
  bottom: -3px;
  left: 0;
  transition: width .3s ease, opacity .3s ease;
  opacity: 0;
}

.link--underline:hover:before {
  width: 50px;
  transition: width .3s ease, opacity .3s ease;
  opacity: 1;
}

.layout--inset .link--underline:hover:before {
  background: #b01116;
}

@media screen and (min-width: 568px) {
  .layout--inset {
    padding: 30px;
    background: white;
  }
}

@media screen and (min-width: 1140px) {
  .layout--boxed {
    padding: 30px;
    max-width: 1140px;
    margin: 0 auto;
  }
  .layout--boxed.cta {
    padding-top: 0;
  }
}

.header {
  position: relative;
}

.logo,
.nav {
  position: absolute;
  z-index: 5;
  top: 15px;
}

.logo {
  width: 100px;
  left: 15px;
  transition: width .3s ease;
}

.hamburger {
  position: absolute;
  right: 15px;
  z-index: 6;
  opacity: 1;
  transition: opacity .3s ease;
  visibility: visible;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .logo {
    width: 150px;
    transition: width .3s ease;
  }
}

@media screen and (min-width: 1024px) {
  .hamburger {
    opacity: 0;
    visibility: hidden;
    transition: opacity .3s ease;
  }
}

.nav {
  right: -50%;
  background: #b01116;
  width: 50%;
  top: 0;
  height: 100%;
  display: flex;
  align-items: flex-end;
  padding: 15px;
  transition: right 0.3s ease;
}

.nav__list {
  padding: 0 10px;
  margin: 0;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.nav__item {
  display: block;
  text-transform: uppercase;
  padding: 10px 0 0;
  clear: both;
}

.nav__item :link,
.nav__item :visited {
  color: white;
}

.nav.is-visible {
  right: 0;
  transition: right 0.3s ease;
}

.nav.is-visible .nav__list {
  opacity: 1;
  transition-delay: 0.3s;
}

@media screen and (min-width: 768px) {
  .nav__list {
    padding: 10px;
    font-size: 15px;
    opacity: 1;
  }
  .nav__item {
    padding: 15px 0 0;
  }
}

@media screen and (min-width: 1024px) {
  .nav {
    right: 30px;
    background: transparent;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .nav__list {
    display: block;
    width: calc(100% - 30px);
    text-align: right;
    font-size: 14px;
  }
  .nav__item {
    display: inline-block;
    margin-left: 15px;
  }
}

.hero-img {
  height: 40vh;
  min-height: 200px;
  max-height: 630px;
  background: url("/assets/images/header_image.jpg") top center no-repeat;
  background-size: cover;
  position: relative;
  z-index: 0;
  overflow: hidden;
}

.hero-img__overlay {
  background: rgba(176, 17, 22, 0.65);
  position: absolute;
  height: 100%;
  width: 50%;
  right: 0;
  top: 0;
  z-index: 1;
}

.hero-img__slogan {
  text-transform: uppercase;
}

.hero-img__container {
  position: relative;
  height: 100%;
}

.hero-img__slogan {
  color: white;
  position: absolute;
  bottom: 15px;
  width: 10px;
  left: 50vw;
  font-size: 26px;
  letter-spacing: 2px;
  transition: font-size .3s ease, bottom .3s ease;
  z-index: 2;
}

.hero-img__slogan--left, .hero-img__slogan--right {
  position: absolute;
  bottom: 0;
  width: calc(50vw - 8px);
  display: block;
}

.hero-img__slogan--left {
  left: -50vw;
  text-align: right;
}

.hero-img__slogan--right {
  left: 8px;
}

@media screen and (min-width: 768px) {
  .hero-img__slogan {
    font-size: 60px;
    bottom: 40px;
  }
}

@media screen and (min-width: 1140px) {
  .hero-img__container {
    padding: 30px;
  }
}

.main {
  padding: 0;
}

.cta {
  text-align: center;
  margin-bottom: 15px;
}

.cta__list {
  margin: 0;
  padding: 0;
}

.cta__list--item {
  display: inline;
  padding-left: 10px;
  margin-left: 10px;
  position: relative;
}

.cta__list--item:before {
  content: '\2022';
  position: absolute;
  left: -7px;
  top: 0px;
}

.cta__list--item:first-child {
  padding-left: 0;
  margin-left: 0;
  background: transparent;
}

.cta__list--item:first-child:before {
  content: '';
}

.row-content__image {
  margin-bottom: 15px;
}

.row-content__video {
  margin-bottom: 15px;
  width: 100%;
  min-height: 180px;
  background-color: black;
}

.row-content:after {
  content: '';
  display: block;
  clear: both;
}

.text-link {
  font-weight: bold;
  text-decoration: none;
  position: relative;
  padding-right: 20px;
}

.text-link:after {
  content: '\203A';
  display: block;
  position: absolute;
  font-size: 20px;
  right: 0;
  top: -1px;
}

.text-link:hover {
  color: #b01116;
}

.maps-frame {
  height: 40vh;
  width: 100vw;
  position: relative;
}

@media screen and (min-width: 768px) {
  .row-content__image {
    max-width: 400px;
  }
  .row-content__video {
    max-width: 500px;
    min-height: 280px;
    margin-left: 20px;
  }
  .row-content--right {
    float: right;
  }
}

@media screen and (min-width: 1140px) {
  .main {
    padding: 15px 0 0;
  }
}

.team {
  margin-bottom: 15px;
}

.team .layout--inset:after {
  content: '';
  clear: both;
  display: block;
}

.team__member {
  margin-top: 15px;
  margin-bottom: 15px;
}

.team__member--image {
  display: block;
  width: 100%;
  padding-top: 85%;
  position: relative;
  overflow: hidden;
  margin-bottom: 15px;
}

.team__member--name {
  font-size: 26px;
  line-height: 30px;
}

.team__member--function {
  font-size: 20px;
  line-height: 26px;
  font-style: italic;
}

.team__member--image > * {
  position: absolute;
  width: 100%;
  top: 50%;
  transform: translateY(-50%);
}

@media screen and (min-width: 568px) {
  .team__member {
    width: calc(50% - 15px);
    float: left;
    margin-left: 30px;
  }
  .team__member:nth-child(even) {
    margin-left: 0;
  }
}

@media screen and (min-width: 768px) {
  .team__member {
    width: calc(33% - 20px);
  }
  .team__member:nth-child(even) {
    margin-left: 30px;
  }
  .team__member:nth-child(3n+2) {
    margin-left: 0;
  }
}

@media screen and (min-width: 1024px) {
  .team__member {
    width: calc(33% - 23px);
  }
  .team__member:nth-child(even) {
    margin-left: 30px;
  }
  .team__member:nth-child(3n+2) {
    margin-left: 30px;
  }
  .team__member:nth-of-type(4n+1) {
    margin-left: 0;
  }
}

.socials {
  display: block;
  margin: 10px 0 0;
  padding: 0;
}

.socials > * {
  display: inline-block;
}

.btn-social {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  text-decoration: none;
  text-indent: -9999px;
}

.btn-social--facebook {
  background: url("/assets/images/icon-facebook.svg") center center no-repeat;
  background-size: auto 100%;
}

.btn-social--instagram {
  background: url("/assets/images/icon-instagram.svg") center center no-repeat;
  background-size: auto 100%;
}

.btn-social--pinterest {
  background: url("/assets/images/icon-pinterest.svg") center center no-repeat;
  background-size: auto 100%;
}

.contact__row {
  margin: 15px 0;
}

.contact__row--mail, .contact__row--phone {
  display: block;
  padding: 0 0 0 30px;
  text-decoration: none;
  position: relative;
}

.contact__row--mail:hover, .contact__row--phone:hover {
  text-decoration: underline;
  cursor: pointer;
  color: white;
}

.contact__row--mail:before, .contact__row--phone:before {
  position: absolute;
  content: '';
  width: 20px;
  height: 20px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.contact__row--mail:before {
  background: url("/assets/images/icon-mail.svg") center center no-repeat;
  background-size: 100% auto;
}

.contact__row--phone:before {
  background: url("/assets/images/icon-phone.svg") center center no-repeat;
  background-size: 100% auto;
}

.fullwidth {
  position: relative;
  margin-bottom: 15px;
}

.fullwidth__image {
  height: 30vh;
  min-height: 200px;
  overflow: hidden;
  display: block;
  width: 100%;
}

.fullwidth__image > * {
  height: 100%;
  min-width: 100%;
  object-fit: cover;
}

.fullwidth__overlay {
  position: relative;
  width: calc(100% - 30px);
  margin: -20px auto 0;
  color: white;
  background: url("/assets/images/background_overlay.jpg") center center no-repeat;
  background-size: cover;
  padding: 15px;
  min-height: 280px;
}

.fullwidth__overlay .text-link:hover {
  color: white;
}

.fullwidth__logo {
  position: absolute;
  bottom: 5px;
  left: 30px;
  display: none;
}

@media screen and (min-width: 768px) {
  .fullwidth {
    margin-bottom: 30px;
  }
  .fullwidth__image {
    height: 40vh;
    min-height: 400px;
  }
  .fullwidth__overlay {
    position: absolute;
    bottom: -30px;
    max-width: 540px;
    margin: 0;
    padding: 30px;
    min-height: 350px;
  }
  .fullwidth__overlay--right {
    right: 15px;
  }
  .fullwidth__overlay--left {
    left: 15px;
  }
}

@media screen and (min-width: 1140px) {
  .fullwidth__overlay {
    margin: 0 15px;
  }
  .fullwidth__logo {
    display: initial;
  }
  .overlay__container {
    background: transparent;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 1140px;
  }
}

.static-feed {
  margin: 15px auto;
  display: flex;
  flex-flow: row wrap;
}

.static-feed:after {
  content: '';
  display: block;
  clear: both;
}

.static-feed__tags, .static-feed__posts {
  display: block;
  width: 100%;
}

.static-feed__tags {
  order: 1;
}

.static-feed__tags > * {
  display: block;
  width: 100%;
}

.static-feed__posts {
  order: 2;
  margin-top: 15px;
}

.static-feed__button {
  order: 3;
  display: block;
  width: 100%;
  background-color: #b01116;
  color: white;
  font-size: 20px;
  text-align: center;
  padding: 15px 0;
  transition: background-color 0.3s ease;
}

.static-feed__button:hover {
  background-color: #810d10;
  color: white;
}

.static-feed__post {
  position: relative;
  float: left;
  width: calc(50% - 8px);
  padding-top: 45%;
  overflow: hidden;
  margin-bottom: 15px;
}

.static-feed__post:nth-of-type(even) {
  margin-left: 15px;
}

.static-feed__post > * {
  position: absolute;
}

.static-feed__post--image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-width: 100%;
  min-height: 100%;
}

.static-feed__post--author {
  display: block;
  width: 100%;
  bottom: 0;
  background-color: rgba(176, 17, 22, 0.5);
  text-align: center;
  color: white;
  font-weight: bold;
  padding: 10px 0;
  transition: background-color 0.3s ease;
}

.static-feed__post:hover .static-feed__post--author {
  background: #b01116;
}

@media screen and (min-width: 568px) {
  .static-feed__post {
    width: calc(33% - 10px);
    padding-top: 33%;
    margin-left: 15px;
  }
  .static-feed__post:nth-of-type(even) {
    margin-left: 15px;
  }
  .static-feed__post:nth-child(3n+1) {
    margin-left: 0;
  }
  .static-feed__button {
    margin-left: auto;
    width: 66%;
  }
}

@media screen and (min-width: 768px) {
  .static-feed__tags > * {
    display: inline-block;
    width: 25%;
    padding: 10px 0;
  }
  .static-feed__post {
    width: calc(25% - 12px);
    padding-top: 25%;
  }
  .static-feed__post:nth-child(3n+1) {
    margin-left: 15px;
  }
  .static-feed__post:nth-child(4n+1) {
    margin-left: 0;
  }
  .static-feed__tags {
    order: 2;
  }
  .static-feed__posts {
    order: 1;
  }
  .static-feed__button {
    width: 50%;
    margin-top: -50px;
  }
}

@media screen and (min-width: 1024px) {
  .static-feed__posts {
    margin-bottom: 15px;
  }
  .static-feed__post {
    width: calc(20% - 12px);
    padding-top: 20%;
  }
  .static-feed__post:nth-child(4n+1) {
    margin-left: 15px;
  }
  .static-feed__post:nth-child(5n+1) {
    margin-left: 0;
  }
  .static-feed__button {
    width: calc(40% - 10px);
  }
}

.hamburger {
  padding: 15px 15px;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}

.hamburger:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 19px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 30px;
  height: 3px;
  background-color: white;
  border-radius: 3px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}

.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}

.hamburger-inner::before {
  top: -8px;
}

.hamburger-inner::after {
  bottom: -8px;
}

.hamburger--slider .hamburger-inner {
  top: 1.5px;
}

.hamburger--slider .hamburger-inner::before {
  top: 8px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}

.hamburger--slider .hamburger-inner::after {
  top: 16px;
}

.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 8px, 0) rotate(45deg);
}

.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-4.2857142857px, -5px, 0);
  opacity: 0;
}

.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -16px, 0) rotate(-90deg);
}

.lookbook-container {
  background: transparent;
  padding: 60px 0;
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}

.lookbook {
  padding: 0 30px;
}

.tile-container {
  padding-top: 30px;
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
}

.button {
  background: #b01116;
  color: white;
  padding: 10px 15px;
  text-align: center;
}

.button:hover {
  color: white;
  background: #810d10;
}

.tile {
  background: white;
  position: relative;
  display: block;
  width: 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}

.tile__heading {
  background: #F6F6F6;
  padding: 15px 15px 5px;
}

.tile__img {
  position: relative;
  width: 100%;
  padding-top: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
}

.tile__img > * {
  width: 100%;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.tile__content {
  width: 100%;
  padding: 0 25px 25px 25px;
}

.tile__content:after {
  display: block;
  content: '';
  clear: both;
}

.tile__footer {
  padding: 15px 15px;
  background: #F6F6F6;
  font-size: 14px;
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  clear: both;
  text-align: center;
}

.tile__nav {
  padding: 5px 5px 5px 10px;
}

.tile__nav img {
  width: 30%;
  margin: 6px 1px;
  border: 3px solid white;
  cursor: pointer;
}

.tile .image-is-active {
  border: 3px solid #b01116;
}

.piece {
  display: block;
  width: 100%;
  padding: 3px 0;
  font-size: 17px;
  border-bottom: 1px dotted #DEDEDE;
}

.piece__desc {
  float: left;
  width: calc(100% - 120px);
}

.piece__price {
  width: 100px;
  margin-left: 20px;
  float: left;
  text-align: right;
  color: #555;
}

.piece__specs {
  color: #A0A0A0;
  font-size: 13px;
  line-height: 19px;
  margin-top: -4px;
}

.piece:after {
  display: block;
  content: '';
  clear: both;
}

.sale-overlay {
  position: absolute;
  z-index: 99;
  display: block;
  background: #099395;
  color: white;
  opacity: 1;
  width: 120px;
  height: 120px;
  left: auto;
  right: -9px;
  font-weight: bold;
  text-align: center;
  border-radius: 50%;
  transform: rotate(20deg);
  box-shadow: 5px 2px 4px rgba(0, 0, 0, 0.2);
  top: -20px;
}

.sale-overlay__title, .sale-overlay__percentage {
  position: absolute;
  width: 100%;
  top: 30%;
  left: 0;
}

.sale-overlay__title {
  font-size: 25px;
  margin-top: -15px;
  font-weight: normal;
}

.sale-overlay__percentage {
  font-size: 40px;
  margin-top: 20px;
}

.pricespan {
  white-space: nowrap;
}

.oldprice {
  display: block;
  font-size: 14px;
  text-decoration: line-through;
  margin-bottom: -7px;
}

.newprice {
  display: inline-block;
  font-weight: bold;
  color: #111;
}

@media screen and (min-width: 600px) {
  .tile__img {
    float: left;
    width: 40%;
    padding-top: 40%;
  }
  .tile__content {
    float: left;
    width: 60%;
  }
  .tile__footer {
    grid-template-columns: 1fr 185px;
    text-align: left;
    align-items: center;
  }
  .tile__footer > * {
    margin: 0;
  }
  .tile__nav img {
    width: 100px;
  }
}

.footer {
  background-color: #b01116;
  padding: 15px;
  font-size: 16px;
  color: white;
}

.footer__column {
  margin-bottom: calc(2 * 30px);
}

.footer__column:last-child {
  margin-bottom: 0;
}

.footer__column :link {
  color: white;
}

.footer__column :link:hover {
  text-decoration: underline;
}

.footer .btn-social {
  width: 25px;
  height: 25px;
  margin-top: 10px;
}

.footer .btn-social--facebook {
  background: url("/assets/images/icon-facebook-inverse.svg") center center no-repeat;
  background-size: auto 100%;
}

.footer .btn-social--instagram {
  background: url("/assets/images/icon-instagram-inverse.svg") center center no-repeat;
  background-size: auto 100%;
}

.footer .btn-social--pinterest {
  background: url("/assets/images/icon-pinterest-inverse.svg") center center no-repeat;
  background-size: auto 100%;
}

.footer .copyright {
  color: white;
}

@media screen and (min-width: 768px) {
  .footer .container {
    display: flex;
  }
  .footer__column {
    flex: 1;
    width: 0;
    margin-bottom: 0;
  }
  .footer .copyright {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .footer .contact__row {
    margin-bottom: 0;
  }
}

.iconic-pagination {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.iconic-pagination__link {
  font-weight: bold;
  text-decoration: none;
  position: relative;
}

.iconic-pagination__link:hover:link, .iconic-pagination__link:hover:visited {
  color: #b01116;
}

.iconic-pagination__link--is-disabled {
  font-weight: normal;
  color: gray;
}

.iconic-pagination__link--prev {
  padding-left: 20px;
}

.iconic-pagination__link--prev::before {
  content: '\2039';
  display: block;
  position: absolute;
  font-size: 20px;
  left: 0;
  top: -1px;
  font-weight: bold;
}

.iconic-pagination__link--next {
  padding-right: 20px;
}

.iconic-pagination__link--next::after {
  content: '\203A';
  display: block;
  position: absolute;
  font-size: 20px;
  right: 0;
  top: -1px;
  font-weight: bold;
}
