.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    cursor: pointer;
    
    transition-property: opacity, filter;
    transition-duration: $hamburger-hover-transition-duration;
    transition-timing-function: $hamburger-hover-transition-timing-function;
    
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;
    
    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
            }
        @else {
            opacity: $hamburger-hover-opacity;
            }
        }
    }

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
    }

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;
    
    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        }
    
    &::before,
    &::after {
        content: "";
        display: block;
        }
    
    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    
    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        }
    }

@if index($hamburger-types, slider) {
    
    .hamburger--slider {
        .hamburger-inner {
            top: $hamburger-layer-height / 2;
            
            &::before {
                top: $hamburger-layer-height + $hamburger-layer-spacing;
                transition-property: transform, opacity;
                transition-timing-function: ease;
                transition-duration: 0.15s;
                }
            
            &::after {
                top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
                }
            }
        
        &.is-active {
            .hamburger-inner {
                $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;
                
                transform: translate3d(0, $y-offset, 0) rotate(45deg);
                
                &::before {
                    transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
                    opacity: 0;
                    }
                
                &::after {
                    transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
                    }
                }
            }
        }
    }