*,
*:before,
*:after {
    box-sizing: border-box;
    }

html,
body {
    height: 100%;
    padding:0;
    margin:0;
    }

body {
    color: $color-font;
    font-size: 16px;
    background-color: $color-body-bg;
    line-height:24px;
    overflow-x:hidden;
    }

img {
    max-width: 100%;
    vertical-align: middle;
    }

iframe,
object,
embed {
    max-width: 100%;
    vertical-align: middle;
    }

p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0 0 1em 0;
    }

:link {
    text-decoration: none;
    cursor: pointer;
    }

:link:hover {
    color: $color-primary;
    }

:link,
:visited {
    color: inherit;
    }

p {
    line-height: 1.4em;
    margin-bottom: 1.25em;
}

.cf::after {
    content: "";
    display: table;
    clear: both;
    }

.is-visually-hidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    }

.absolute {
    position: absolute;
    }



body {
    font-family: $font-copy;
    }

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $font-heading;
    font-weight: 200;
    letter-spacing:1px;
    text-transform: uppercase;
    }


h1 {
    font-size:28px;
    line-height:28px;
    }

h2 {
    font-size:26px;
    line-height:30px;
    }

@media screen and (min-width:768px) {
    body {
        font-size: 18px;
        line-height:27px;
        }
    
    h1 {
        font-size:30px;
        line-height:30px;
        }
    h2 {
        font-size:21px;
        line-height:25px;
        }
    }

