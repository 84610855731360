.contact__row {
    margin:$grid-gutter-half 0;
    
    &--mail,
    &--phone {
        display: block;
        padding:0 0 0 30px;
        text-decoration: none;
        position: relative;
        
        &:hover {
            text-decoration: underline;
            cursor:pointer;
            color:white;
            }
        }
    
    &--mail:before,
    &--phone:before {
        position: absolute;
        content:'';
        width:20px;
        height:20px;
        left:0;
        top:50%;
        transform:translateY(-50%);
        }
    
    &--mail:before {
        background:url("/assets/images/icon-mail.svg") center center no-repeat;
        background-size:100% auto;
        }
    
    &--phone:before {
        background:url("/assets/images/icon-phone.svg") center center no-repeat;
        background-size:100% auto;
        }
    }
