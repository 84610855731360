.nav {
    right:-50%;
    background:$color-primary;
    width:50%;
    top:0;
    height:100%;
    display: flex;
    align-items: flex-end;
    padding:$grid-gutter-half;
    transition: right 0.3s ease;
    
    &__list {
        padding:0 10px;
        margin:0;
        opacity: 0;
        transition: opacity 0.5s ease;
        }
    
    &__item {
        display: block;
        text-transform: uppercase;
        padding:10px 0 0;
        clear: both;
        }
    
    &__item :link,
    &__item :visited {
        color:white;
        }
    
    &.is-visible {
        right:0;
        transition: right 0.3s ease;
        }
    
    }

.nav.is-visible {
    
    .nav__list {
        opacity: 1;
        transition-delay: 0.3s;
        }
    }

@media screen and (min-width:$bp_md) {
    
    .nav {
    
        &__list {
            padding:10px;
            font-size: 15px;
            opacity: 1;
            }
        
        &__item {
            padding:15px 0 0;
            }
        }
    
    }

@media screen and (min-width:$bp_lg) {
    
    .nav {
        right:$grid-gutter;
        background:transparent;
        align-items:flex-start;
        justify-content:flex-end;
        
        &__list {
            display: block;
            width:calc(100% - 30px);
            text-align:right;
            font-size:14px;
            }
        
        &__item {
            display: inline-block;
            margin-left:$grid-gutter-half;
            }
        }
        
    }