.static-feed {
    margin:$grid-gutter-half auto;
    display: flex;
    flex-flow: row wrap;
    
    &:after {
        content:'';
        display: block;
        clear:both;
        }
    
    &__tags,
    &__posts {
        display: block;
        width:100%;
        }
    
    &__tags {
        order:1;
        }
    
    &__tags > * {
        display: block;
        width:100%;
        }
    
    &__posts {
        order:2;
        margin-top:$grid-gutter-half;
        }
    
    &__button {
        order:3;
        display: block;
        width:100%;
        background-color:$color-primary;
        color:white;
        font-size:20px;
        text-align: center;
        padding:15px 0;
        transition:background-color 0.3s ease;
        }
    
    &__button:hover {
        background-color:darken($color-primary, 10%);
        color:white;
        }
    
    &__post {
        position: relative;
        float:left;
        width:calc(50% - 8px);
        padding-top:45%;
        overflow:hidden;
        margin-bottom:$grid-gutter-half;
        }
    
    &__post:nth-of-type(even) {
        margin-left:$grid-gutter-half;
        }
    
    &__post > * {
        position: absolute;
        }
        
    }

.static-feed__post {
    
    &--image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        min-height: 100%;
        }
    
    &--author {
        display: block;
        width:100%;
        bottom:0;
        background-color:rgba($color-primary, 0.5);
        text-align: center;
        color:white;
        font-weight: bold;
        padding:10px 0;
        transition: background-color 0.3s ease;
        }
    
    &:hover .static-feed__post--author {
        background:rgba($color-primary, 1);
        }
    }

@media screen and (min-width:$bp_sm) {
    
    .static-feed {
        
        &__post {
            width: calc(33% - 10px);
            padding-top: 33%;
            margin-left:$grid-gutter-half;
            }
        
        &__post:nth-of-type(even) {
            margin-left:$grid-gutter-half;
            }
        
        &__post:nth-child(3n+1) {
            margin-left:0;
            }
        
        &__button {
            margin-left:auto;
            width:66%;
            }
        }
    
    }

@media screen and (min-width:$bp_md) {
    
    .static-feed {
        
        &__tags > * {
            display: inline-block;
            width:25%;
            padding:10px 0;
            }
        
        &__post {
            width: calc(25% - 12px);
            padding-top: 25%;
            }
        
        &__post:nth-child(3n+1) {
            margin-left:$grid-gutter-half;
            }
        
        &__post:nth-child(4n+1) {
            margin-left:0;
            }
        
        &__tags {
            order:2;
            }
        
        &__posts {
            order:1;
            }
        
        &__button {
            width:50%;
            margin-top:-50px;
            }
        }
    
    }

@media screen and (min-width:$bp_lg) {
    
    .static-feed {
        
        &__posts {
            margin-bottom:$grid-gutter-half;
            }
        
        &__post {
            width: calc(20% - 12px);
            padding-top: 20%;
            }
        
        &__post:nth-child(4n+1) {
            margin-left:$grid-gutter-half;
            }
        
        &__post:nth-child(5n+1) {
            margin-left:0;
            }
        
        &__button {
            width:calc(40% - 10px);
            }
        }
        
    }