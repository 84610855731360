.lookbook-container {
    background:transparent;
    padding:60px 0;
    width:100%;
    max-width:1140px;
    margin:0 auto;
}

.lookbook {
    padding:0 30px;
}

.tile-container {
    padding-top:30px;
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 40px;
}

.button {
    background:$color-primary;
    color:white;
    padding:10px 15px;
    text-align: center;
    
    &:hover {
        color:white;
        background:darken($color-primary, 10%);
    }
}

.tile {
    background:white;
    position: relative;
    display:block;
    width:100%;
    box-shadow:0 0 5px rgba(0,0,0,0.2);
    
    &__heading {
        background:#F6F6F6;
        padding:15px 15px 5px;
    }
    
    &__img {
        position: relative;
        width:100%;
        padding-top:100%;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
    }
    
    &__img > * {
        width:100%;
        opacity: 0;
        position: absolute;
        top:0;
        left:0;
    }
    
    &__content {
        width:100%;
        padding:0 25px 25px 25px;
    }
    
    &__content:after {
        display: block;
        content:'';
        clear:both;
    }
    
    &__footer {
        padding:15px 15px;
        background:#F6F6F6;
        font-size:14px;
        display:grid;
        grid-template-columns: 1fr;
        width:100%;
        clear:both;
        text-align: center;
    }
    
    &__nav {
        padding:5px 5px 5px 10px;
        img {
            width:30%;
            margin:6px 1px;
            border:3px solid white;
            cursor:pointer
        }
    }
    
    .image-is-active {
        border:3px solid $color-primary;
    }
}

.piece {
    display: block;
    width:100%;
    padding:3px 0;
    font-size:17px;
    border-bottom:1px dotted #DEDEDE;
    
    &__desc {
        float:left;
        width:calc(100% - 120px);
    }
    
    &__price {
        width:100px;
        margin-left:20px;
        float:left;
        text-align: right;
        color:#555;
    }
    
    &__specs {
        color:#A0A0A0;
        font-size:13px;
        line-height:19px;
        margin-top:-4px;
    }
    
    &:after {
        display: block;
        content:'';
        clear:both;
    }
}

.sale-overlay {
    position: absolute;
    z-index: 99;
    display: block;
    background: #099395;
    color: white;
    opacity: 1;
    width: 120px;
    height: 120px;
    left: auto;
    right: -9px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    transform: rotate(20deg);
    box-shadow: 5px 2px 4px rgba(0,0,0,0.2);
    top: -20px;
    
    &__title,
    &__percentage {
        position: absolute;
        width:100%;
        top:30%;
        left:0;
    }
    
    &__title {
        font-size:25px;
        margin-top:-15px;
        font-weight: normal;
    }
    
    &__percentage {
        font-size: 40px;
        margin-top: 20px;
    }
    
}

.pricespan {
    white-space: nowrap;
}

.oldprice {
    display: block;
    font-size:14px;
    text-decoration: line-through;
    margin-bottom:-7px;
}

.newprice {
    display: inline-block;
    font-weight: bold;
    color:#111;
}

@media screen and (min-width:600px) {
    .tile {
        
        &__img {
            float:left;
            width:40%;
            padding-top:40%;
        }
        
        &__content {
            float:left;
            width:60%;
        }
        
        &__footer {
            grid-template-columns: 1fr 185px;
            text-align: left;
            align-items:center;
            
            & > * {
                margin:0;
            }
        }
    
        &__nav {
            img {
                width:100px;
            }
        }
    }
}